import { getCabinetUrl } from '@/utils/getCabinetUrl';
import type { PageContext } from 'vite-plugin-ssr/types';
import { createPageApp } from './app';
import { PAGE_SCROLL_PREFIX } from '@/constants/metrics';
import { reachGoal } from '@/plugins/ym-plugin';
import { nextTick } from 'vue';
import throttle from 'lodash.throttle';
// Эта функция отвечает за то как будет рендериться наша страница на клиенте.
// Для каждой странице можно написать свой рендерер в зависимости от потребностей.
// На текущем этапе мне нужно чтобы приложение работало в режиме SSR.
// То есть, чтобы html мы получали только при первом запросе, и вся остальная навигация
// происходила на клиенте

// https://vite-plugin-ssr.com/server-routing-vs-client-routing
// Если тру, роутинг происходит на клиенте. Клиент не запрашивает каждый раз новый html.
// Если фолз, на каждый переход внутри сайта запрашивается новый html
export const clientRouting = true;
// https://vite-plugin-ssr.com/clientRouting#link-prefetching
// Как мы хотим подгружать ресурсы из отрисованых ссылок
// { when: HOVER } - при наведении мышки
// { when: VIEWPORT } - при попадании ссылки во вьюпорте
// false - не хотим
// export const prefetchStaticAssets = { when: 'HOVER' };
export const prefetchStaticAssets = false;

let app: Awaited<ReturnType<typeof createPageApp>>;

const scrollGoals = [100, 75, 50, 25];

const triggeredGoals = new Set<number>();

const getScrollPercentage = (): number => {
  const scrollTop = window.scrollY;

  const viewportHeight = window.innerHeight;

  const totalHeight = document.documentElement.scrollHeight;

  if (totalHeight <= viewportHeight) {
    return 100; // Если скролл невозможен, возвращаем 100%
  }

  // Вычисляем видимую часть контента
  const visibleContent = (scrollTop + viewportHeight) / totalHeight;

  // Возвращаем процент, ограничив его 100% для случаев, когда прокручена вся страница
  return Math.min(100, visibleContent * 100);
};

const sendGoal = (goal: number) => {
  reachGoal(PAGE_SCROLL_PREFIX + goal);
  triggeredGoals.add(goal);
};

const checkScrollGoals = () => {
  if (triggeredGoals.size === scrollGoals.length) {
    return;
  }
  nextTick(() => {
    const scrollPercentage = getScrollPercentage();

    // Находим первую подходящую
    const highestGoal = scrollGoals.find((goal) => scrollPercentage >= goal);

    if (highestGoal && !triggeredGoals.has(highestGoal)) {
      sendGoal(highestGoal);
    }
  });
};

const handleScroll = throttle(checkScrollGoals, 200);

// pageContext - объект плагина, в котором хранятся все данные о текущей странице.
async function render(pageContext: PageContext) {
  if (!app) {
    // Если внутри html который пришел с бека нет контента, считаем что это клинет онли страница
    app = await createPageApp(
      pageContext,
      document.getElementById('page')?.innerHTML === '',
      pageContext.initialSiteData,
      true,
    );
    app.mount('#app');

    window.addEventListener('scroll', handleScroll);
  } else {
    const store = app.config.globalProperties?.$store;

    const locale = store?.getters?.currentLocale;

    const isLoggedInCabinet = store?.getters?.isLoggedInCabinet;

    const {
      urlParsed: { pathname, searchOriginal },
    } = pageContext;

    const cabinetUrl = isLoggedInCabinet
      ? getCabinetUrl(pathname, searchOriginal ?? '', locale)
      : '';

    if (cabinetUrl) {
      window.location.href = cabinetUrl;
      return;
    }

    app.changePage(pageContext);
  }
}
// Вызывается при первом клике по ссылке
function onPageTransitionStart() {
  app && app.startLoading();
}

// Вызывается когда все данные пришли и надо перерисовать страницу
function onPageTransitionEnd() {
  app && app.stopLoading();

  triggeredGoals.clear();
}

export { render };
export { onPageTransitionStart };
export { onPageTransitionEnd };
