// Сначала ищем websiteID, потом локаль
import type { WebsiteId } from '@/env';
import type { CdekCity } from '@/types/dto/city';

// ЭТО ДЕФОЛТНЫЙ ГОРОД ДЛЯ ОТОБРАЖЕНИЯ НА СТРАНИЦЫ ОФИСОВ
export const DEFAULT_CITIES: Record<WebsiteId, Record<string, CdekCity>> = {
  ru: {
    ru: {
      country_code: 'ru',
      locale: 'ru',
      id: 14895,
      cdek_id: 44,
      name: 'Москва',
      fullName: 'Москва, Москва, Россия',
      url: 'moscow',
      countryCode: 1,
      regionCode: 81,
      latitude: 55.75322,
      longitude: 37.622513,
    },
  },
  kz: {
    ru: {
      country_code: 'kz',
      locale: 'ru',
      id: 14974,
      cdek_id: 4961,
      name: 'Нур-Султан (Астана)',
      fullName: 'Нур-Султан (Астана), Акмолинская обл., Казахстан',
      url: 'nur-sultan-astana',
      countryCode: 48,
      regionCode: 1878,
      latitude: 51.12822,
      longitude: 71.430668,
    },
    kz: {
      country_code: 'kz',
      locale: 'kz',
      id: 9372500,
      cdek_id: 4961,
      name: 'Nur-Sultan (Astana)',
      fullName: 'Nur-Sultan (Astana), Akmolinskaya obl., Kazakhstan',
      url: 'nur-sultan-astana',
      countryCode: 48,
      regionCode: 1878,
      latitude: 51.12822,
      longitude: 71.430668,
    },
  },
  cn: {
    zh: {
      country_code: 'cn',
      locale: 'zh',
      id: 15919,
      cdek_id: 10150,
      name: '北京市',
      fullName: '北京市, 北京, 中国',
      url: 'beijing',
      countryCode: 138,
      regionCode: 693,
      latitude: 39.906217,
      longitude: 116.391275,
    },
    ru: {
      country_code: 'cn',
      locale: 'ru',
      id: 15373,
      cdek_id: 10150,
      name: 'Пекин',
      fullName: 'Пекин, Пекин, Китай (КНР)',
      url: 'beijing',
      countryCode: 138,
      regionCode: 693,
      latitude: 39.906217,
      longitude: 116.391275,
    },
  },
  by: {
    ru: {
      country_code: 'by',
      locale: 'ru',
      id: 14940,
      cdek_id: 9220,
      name: 'Минск',
      fullName: 'Минск, Минская обл., Белоруссия (Беларусь)',
      url: 'minsk',
      countryCode: 42,
      regionCode: 603,
      latitude: 53.902284,
      longitude: 27.561831,
    },
  },
  ge: {
    ru: {
      country_code: 'ge',
      locale: 'ru',
      id: 16316987,
      cdek_id: 11564,
      name: 'Тбилиси',
      fullName: 'Грузия, Тбилиси',
      url: 'tbilisi',
      countryCode: 58,
      regionCode: 1357,
      latitude: 41.693459,
      longitude: 44.801449,
    },
    ge: {
      country_code: 'ge',
      locale: 'ge',
      id: 20407617,
      cdek_id: 11564,
      name: 'Tbilisi',
      fullName: 'Georgia, Tbilisi',
      url: 'tbilisi',
      countryCode: 58,
      regionCode: 1357,
      latitude: 41.693459,
      longitude: 44.801449,
    },
  },
  uz: {
    ru: {
      country_code: 'uz',
      locale: 'ru',
      id: 15305,
      cdek_id: 11562,
      name: 'Ташкент',
      fullName: 'Узбекистан, Ташкент',
      url: 'tashkent',
      countryCode: 46,
      regionCode: 2186,
      latitude: 41.312336,
      longitude: 69.278707,
    },
    uz: {
      country_code: 'uz',
      locale: 'uz',
      id: 20414041,
      cdek_id: 11562,
      name: 'Tashkent',
      fullName: 'Uzbekistan, Tashkent',
      url: 'tashkent',
      countryCode: 46,
      regionCode: 2186,
      latitude: 41.312336,
      longitude: 69.278707,
    },
  },
  kg: {
    ru: {
      country_code: 'kg',
      locale: 'ru',
      id: 14926,
      cdek_id: 5444,
      name: 'Бишкек',
      fullName: 'Киргызстан, город Бишкек, Бишкек',
      url: 'bishkek',
      countryCode: 15,
      regionCode: 1985,
      latitude: 42.877789,
      longitude: 74.606692,
    },
    kg: {
      country_code: 'kg',
      locale: 'kg',
      id: 16390970,
      cdek_id: 5444,
      name: 'Bishkek',
      fullName: 'Bishkek, Chui region, Kyrgyzstan',
      url: 'bishkek',
      countryCode: 15,
      regionCode: 1985,
      latitude: 42.877789,
      longitude: 74.606692,
    },
  },
  am: {
    ru: {
      country_code: 'am',
      locale: 'ru',
      id: 15308,
      cdek_id: 7114,
      name: 'Ереван',
      fullName: 'Ереван',
      url: 'erevan',
      countryCode: 41,
      regionCode: 323,
      latitude: 40.177628,
      longitude: 44.512555,
    },
    am: {
      country_code: 'am',
      locale: 'am',
      id: 16396240,
      cdek_id: 7114,
      name: 'Erevan',
      fullName: 'Erevan',
      url: 'erevan',
      countryCode: 41,
      regionCode: 323,
      latitude: 40.177628,
      longitude: 44.512555,
    },
  },
  az: {
    ru: {
      id: 8066219,
      cdek_id: 5090,
      name: 'Баку',
      fullName: 'Азербайджан, Баку, Баку',
      locale: 'ru',
      country_code: 'az',
      url: 'baku',
      countryCode: 40,
      regionCode: 1535,
      latitude: 40.375443,
      longitude: 49.832674,
    },
    az: {
      id: 8066219,
      cdek_id: 5090,
      name: 'Baku',
      fullName: 'Baku',
      locale: 'az',
      country_code: 'az',
      url: 'baku',
      countryCode: 40,
      regionCode: 1535,
      latitude: 40.375443,
      longitude: 49.832674,
    },
  },
};
